.profile-pic {
	border-radius: 50%;
	border: 1px solid #DEDEDE;
	overflow: hidden;
	width: 65px;
	height: 65px;
	&.tumbnail {
		width: 33px;
		height: 33px;
	}
	img {
		width: 100%;
	}
}

.profile-icon i {
	width: 33px;
	height: 33px;
	font-size: 33px;
}

.bold {
	font-weight: 900;
}

.italic {
	font-style: italic;
}

.pill {
	.pill-content {
		border-radius: 0.5rem;
		border: 1px solid #6E6E6E;
		margin-bottom: 0;
		margin-top: 0.3rem;
		font-weight: 600;
		.pill-left {
			border-bottom-left-radius: 0.5rem;
			border-top-left-radius: 0.5rem;
			padding-right: 0.4rem;
			padding-left: 0.4rem;
			display: inline-block;
			color: #FFFFFF;
    		background-color: #6E6E6E;
		}
	
		.pill-right {
			border-bottom-right-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
			padding-right: 0.4rem;
			padding-left: 0.4rem;
			display: inline-block;
			color: #6E6E6E;
    		background-color: #FFFFFF;
		}
	}
}

.clickable {
	cursor: pointer;
}

body {
    margin: 0;
    padding: 0px; // Example of adding global padding
}