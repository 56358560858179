// #root {
//   height: 100vh;
//   // overflow-y: hidden;
// }
.heroshot-layout {
  &.students,
  &.approvals {
    // .content-area .container:not(navbar-lift) {
    .content-area .container {
      &.navbar-lift {
        height: auto;
      }
      height: 100%;
      .col-12 {
        height: 100%;
      }
    }
  }
}

.container {
  max-width: initial !important;
}

.invitee-name,
a {
  font-weight: 600;
  cursor: pointer;
  color: #20447d;
  &:hover {
    color: #152b4e !important;
  }
}

.progress-dot {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  margin: 0 auto;

  &.green {
    background-color: #029600;
  }

  &.red {
    background-color: #dc3545;
  }

  &.grey {
    background-color: #6c757d;
  }

  &.yellow {
    background-color: rgb(209, 167, 30);
  }
}

.progress-overview {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-radius: 0.6rem;
  border: 1px solid #dedede;

  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 834px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 566px) {
    grid-template-columns: repeat(1, 1fr);

    .card {
      grid-template-rows: auto !important;
    }
  }

  .card {
    border-radius: 0;
    align-content: start;
    grid-row-gap: 10px;
    grid-template-rows: 70px 80px 90px;
    border-left: 0;
    border-top: 0;
    display: grid;
    padding: 20px 10px;
    justify-items: center;
    .top {
      display: grid;
      align-items: center;
      i {
        // color: #079805;
        font-size: 30px;
        width: 30px;
      }
    }
    .profile-pic {
      border-radius: 50%;
      border: 1px solid #dedede;
      overflow: hidden;
      width: 65px;
      height: 65px;
      img {
        width: 100%;
      }
    }
    &:first-child {
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
    }
    &:nth-child(5) {
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
      border-right: 0;
    }
    .info {
      display: grid;
      justify-items: center;
      text-align: center;
    }
    .circle-bg {
      background-color: #079805;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-bottom: 15px;
      i {
        color: #fff !important;
        font-size: 15px;
        margin-top: 8px;
      }
    }
    p {
      margin-bottom: 0;
      word-break: break-word;
      text-align: center;
    }
    .bottom {
      display: grid;
      align-self: center;
      justify-items: center;
      grid-row-gap: 0.5rem;
      // grid-template-rows: 21px 1fr;
      .btn-padded span {
        padding: 0px 5px;
      }
      .btn {
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.closeDrawer {
  position: absolute !important;
  top: 10px;
  right: 20px;
}

.drawerSize-large {
  .MuiDrawer-paper {
    width: 95%;
    @media only screen and (max-width: 566px) {
      width: 100%;
    }
  }
}
.drawerSize-medium {
  .MuiDrawer-paper {
    width: 55%;
    @media only screen and (max-width: 1600px) {
      width: 60%;
    }
    @media only screen and (max-width: 1280px) {
      width: 70%;
    }
    @media only screen and (max-width: 566px) {
      width: 100%;
    }
  }
}
.drawerSize-small {
  .MuiDrawer-paper {
    width: 30%;
    @media only screen and (max-width: 834px) {
      width: 50%;
    }
    @media only screen and (max-width: 566px) {
      width: 100%;
    }
  }
}

#student-search,
#department-select,
#year-select,
#status-select {
  background-color: white;
}

.school-search {
  width: 100%;
}

.pulsate-loading {
  margin: 0 0 0 0.5rem;
  border: 3px solid #fff;
  height: 16px;
  width: 16px;
  animation: pulsate 0.6s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
}

.HuiTablePagination {
  > div {
    &:nth-child(2) {
      width: 75px !important;
    }
  }
}

.MuiPickersDay-root {
  font-weight: 600 !important;
}

.MuiPickersDay-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.1) !important;
}

.MuiModal-root > div.MuiBox-root {
  min-width: 550px;
}

::-webkit-scrollbar {
  width: 5px;
}

#student-status-search {
  background-color: white;
}

#date-range-picker {
  .MuiFilledInput-root {
    background-color: white;
  }
}
